<template>
 <div class="backBtn">
         <a-button class="backBtn" @click="$emit('backChange')"><slot></slot></a-button>
    </div>
</template>
<script>
export default {
  name: 'vbBack',
  data () {
    return {
    }
  }
}
</script>
<style lang="less" scoped>
.backBtn{margin: 50px 0 70px; text-align: center; overflow: hidden;
  .ant-btn{width:140px;height:44px; background: var(--color-tint); color: #fff;border: none;}
}
</style>
