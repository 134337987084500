<template>
<div>
  <div ref="radarChart" class="mainChart"></div>
</div>
</template>
<script>
import { radarOption } from './chart'
const echarts = require('echarts/lib/echarts')
require('echarts/lib/component/title')
require('echarts/lib/component/toolbox')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/grid')
require('echarts/lib/component/legend')
require('echarts/lib/chart/line')
require('echarts/lib/chart/radar')
export default {
  name: 'vbRadarChart',
  props: {
    radarChartDate: {
      type: Array,
      default () {
        return []
      }
    },
    fontSize: {
      type: Number,
      default: 14
    },
    titleSize: {
      type: Number,
      default: 20
    },
    fontSizeFooter: {
      type: Number,
      default: 14
    }
  },
  data () {
    return {
    }
  },
  mounted () {
    radarOption.radar.name.fontSize = this.fontSizeFooter
    radarOption.legend.textStyle.fontSize = this.fontSize
    radarOption.title.textStyle.fontSize = this.titleSize
    this.getChart()
  },
  methods: {
    chartTransFrom () {
      const color = ['#FF7237', '#41C73D', '#FFC33B', '#DB85FF', '#55CBF9', '#5863FF']
      const lengend = ['第一次测评分值', '第二次测评分值', '第三次测评分值', '第四次测评分值', '第五次测评分值', '第六次测评分值']
      const seriesList = []
      this.radarChartDate.forEach((item, index) => {
        const areaStyle = {
          color: color[index]
        }
        const seriesData = {
          value: item,
          name: lengend[index],
          stack: '综合',
          areaStyle
        }
        radarOption.color.unshift(color[index])
        seriesList.unshift(seriesData)
      })
      radarOption.series = {
        type: 'radar',
        data: [...seriesList]
      }
    },
    getChart () {
      this.chartTransFrom()
      const myChart = echarts.init(this.$refs.radarChart)
      myChart.setOption(radarOption)
      console.log('radarOption', radarOption)
    }
  },
  beforeDestroy () {
    radarOption.series = []
  }
}
</script>
<style scoped>
.mainChart{ width: 1006px; height: 520px; margin: 0 auto; padding:27px 0}
</style>
