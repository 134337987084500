<template>
  <div class="assessmentRecord">
    <div class="shadowSize baseInfo">
      <base-info v-if="isInfo" :infoKeys="infoKeys" :infoValue="infoValue"></base-info>
      <base-info v-else :infoKeys="infoKeysPlan" :infoValue="infoValuePlan"></base-info>
    </div>
    <div class="shadowSize mar_top_36">
      <vb-stage :isShow="false" v-model='second'>
        <template v-slot:title>里程碑评估计分表</template>
      </vb-stage>
      <vbEvaluationRecord v-model="tableForm" :isEdit="false"></vbEvaluationRecord>
    </div>
  </div>
</template>
<script>
import baseInfo from '@/components/content/baseInfo'
import vbStage from '@/components/content/stage'
import vbEvaluationRecord from '@/components/content/evaluationRecord'
import { getRecodeDetail } from '@/api/record'
import { stampToTime } from 'utils/index'
export default {
  name: 'EvaluationRecord',
  components: {
    baseInfo,
    vbStage,
    vbEvaluationRecord
  },
  props: {
    isInfo: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      infoKeys: ['孩子姓名', '评估年龄', '出生日期', '家长姓名', '联系方式', '测评老师'],
      infoKeysPlan: ['孩子姓名', '评估年龄', '测评记录', '家长姓名', '联系方式', '测评老师'],
      infoValuePlan: {},
      infoValue: {},
      tableForm: [],
      mobile: this.$route.query.mobile,
      second: 4
    }
  },
  mounted () {
    this.getRecord()
  },
  methods: {
    transform (stage) {
      const target = []
      stage.forEach((item, key, array) => {
        target.push(item[0])
      })
      return target
    },
    async getRecord () {
      const res = await getRecodeDetail(this.mobile)
      if (res.code !== '1') {
        return this.$message.error(res.msg)
      }
      this.infoValuePlan = {
        child_name: res.data.child_name,
        age: res.data.age,
        num: '第' + res.data.num + '次',
        parent_name: res.data.parent_name,
        mobile: res.data.mobile,
        tea_name: res.data.tea_name
      }
      this.infoValue = {
        child_name: res.data.child_name,
        age: res.data.age,
        birth: stampToTime(res.data.birth, 'YYYY-MM-DD'),
        parent_name: res.data.parent_name,
        mobile: res.data.mobile,
        tea_name: res.data.tea_name
      }
      this.tableForm = [
        this.transform(res.data.one_stage),
        this.transform(res.data.two_stage),
        this.transform(res.data.three_stage)
      ]
      this.second = Number(res.data.second)
    }

  }
}
</script>
<style lang="less" scoped>
::v-deep .baseInfo {
  li:nth-child(2) span:nth-child(1):before {
    background-position: -91px 0;
  }
  li:nth-child(3) span:nth-child(1)::before {
    background-position: -23px 0;
  }
  li:nth-child(4) span:nth-child(1)::before {
    background-position: -184px 0;
  }
  li:nth-child(5) span:nth-child(1)::before {
    background-position: -66px 0;
  }
  li:nth-child(6) span:nth-child(1)::before {
    background-position: -46px 0;
  }
}
</style>
