<template>

  <ul>
    <li v-for="(item,i) in size" :key="i">
      <span>{{infoKeys[i]}} : </span>
      <span> {{infoValue1[i]}}</span>
      </li>
  </ul>
</template>
<script>
export default {
  name: 'baseInfo',
  props: {
    infoKeys: {
      type: Array,
      default () {
        return []
      }
    },
    infoValue: {
      type: Object,
      default () {
        return {}
      }
    },
    size: {
      type: Number,
      default: 6
    }
  },
  data () {
    return {

    }
  },
  computed: {
    infoValue1 () {
      return Object.values(this.infoValue)
    }
  }
}
</script>
<style lang="less" scoped>
ul {
  width: 823px;
  margin: 0 auto;
  overflow: hidden;
  padding-bottom: 24px;
  li {
    float: left;
    width: 33.3%;
    font-size: 16px;
    text-align: center;
    padding-top: 24px;
    line-height: 16px;
    display: flex;

    >span:nth-child(1){width: 130px; text-align: right;}
    >span:nth-child(2){text-align: left;flex:1;width: 100%;padding-left:5px}
  }
  li span:nth-child(1)::before {
    width: 20px;
    height: 20px;
    background: url('../../../assets/images/icon.png') no-repeat 0 -2px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
  }
  li:nth-child(2) span:nth-child(1)::before {
    background-position: -23px 0;
  }
  li:nth-child(3) span:nth-child(1)::before {
    background-position: -45px 0;
  }
  li:nth-child(4) span:nth-child(1)::before {
    background-position: -66px 0;
  }
  li:nth-child(5)::before {
    background-position: -91px 0;
  }
  li:nth-child(6)::before {
    background-position: -114px 0;
  }
}
</style>
