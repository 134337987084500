export const lineOption = {
  title: {
    text: '能力增长曲线',
    textStyle: {
      // fontSize: 30
    }
  },
  color: ['#FF7237', '#41C73D', '#FFC33B', '#DB85FF', '#55CBF9', '#5863FF'],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985'
      }
    }
  },
  legend: {
    data: ['第一次测评分值', '第二次测评分值', '第三次测评分值', '第四次测评分值', '第五次测评分值', '第六次测评分值'],
    textStyle: {},
    left: '13%'
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis:
    {
      type: 'category',
      boundaryGap: false,
      data: ['提要求', '命名', '听者反应', '视觉配对', '独立游戏', '社交', '模仿', '仿说', '发音', '阅读', '书写', 'LRFFC', '对话', '集体技能', '语言结构', '数学'],
      axisLabel: { interval: 0, rotate: 30 }
    },
  yAxis:
    {
      // data: [0, 5, 10, 15]
      axisLabel: { }
    },
  series: []
}
export const radarOption = {
  title: {
    text: '孩子综合能力',
    textStyle: {

    }
  },
  color: [],
  tooltip: {
    trigger: 'item'
    // axisPointer: {
    //   type: 'line',
    //   label: {
    //     backgroundColor: '#6a7985'
    //   }
    // }
  },
  legend: {
    data: ['第一次测评分值', '第二次测评分值', '第三次测评分值', '第四次测评分值', '第五次测评分值', '第六次测评分值'],
    textStyle: {},
    left: '13%'
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '5%',
    containLabel: true
  },
  radar: {
    // shape: 'circle',
    name: {
      color: '#333'
    },
    splitArea: {
      areaStyle: {
        color: ['#fff', '#FFF4ED', '#fff', '#FFF4ED']
      }
    },
    axisLine: {
      lineStyle: {
        color: '#FFF4ED'
      }
    },
    splitLine: {
      lineStyle: {
        color: '#FFF4ED'
      }
    },
    center: ['50%', '55%'],
    indicator: [
      { name: '提要求', max: 15 },
      { name: '命名', max: 15 },
      { name: '听者反应', max: 15 },
      { name: '视觉配对', max: 15 },
      { name: '独立游戏', max: 15 },
      { name: '社交', max: 15 },
      { name: '模仿', max: 10 },
      { name: '仿说', max: 10 },
      { name: '发音', max: 5 },
      { name: '阅读', max: 15 },
      { name: '书写', max: 15 },
      { name: 'LRFFC', max: 15 },
      { name: '对话', max: 15 },
      { name: '集体技能', max: 15 },
      { name: '语言结构', max: 15 },
      { name: '数学', max: 15 }
    ]
  },
  series: {
  }
}
