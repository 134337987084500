<template>
<div>
 <div ref="lineChart" class="mainChart"></div>
 </div>
</template>
<script>
import { lineOption } from './chart'
const echarts = require('echarts/lib/echarts')
require('echarts/lib/component/title')
require('echarts/lib/component/toolbox')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/grid')
require('echarts/lib/component/legend')
require('echarts/lib/chart/line')
require('echarts/lib/chart/radar')
export default {
  name: 'vbLineCharts',
  props: {
    lineChartDate: {
      type: Array,
      default () {
        return []
      }
    },
    fontSize: {
      type: Number,
      default: 14
    },
    titleSize: {
      type: Number,
      default: 20
    },
    fontSizeFooter: {
      type: Number,
      default: 14
    }
  },
  data () {
    return {
    }
  },
  mounted () {
    lineOption.xAxis.axisLabel.fontSize = lineOption.yAxis.axisLabel.fontSize = this.fontSizeFooter
    lineOption.title.textStyle.fontSize = this.titleSize
    lineOption.legend.textStyle.fontSize = this.fontSize
    this.getChart()
  },
  methods: {
    chartTransFrom () {
      const color = ['#FF7237', '#41C73D', '#FFC33B', '#DB85FF', '#55CBF9', '#5863FF']
      const lengend = ['第一次测评分值', '第二次测评分值', '第三次测评分值', '第四次测评分值', '第五次测评分值', '第六次测评分值']
      const seriesList = []
      // const valueList = []
      this.lineChartDate.forEach((item, index) => {
        console.log('item', item)
        const areaStyle = {
          color: color[index]
        }
        const seriesData = {
          data: item,
          name: lengend[index],
          stack: '综合',
          type: 'line',
          areaStyle
        }
        seriesList.push(seriesData)
      })
      lineOption.series = [...seriesList]
    },
    getChart () {
      this.chartTransFrom()
      const myChart = echarts.init(this.$refs.lineChart)
      myChart.setOption(lineOption)
      console.log('lineOption', lineOption)
    }
  },
  beforeDestroy () {
    lineOption.series = []
  }
}
</script>
<style scoped>
.mainChart{ width: 1006px; height: 520px; margin: 0 auto; padding-top:27px}
</style>
